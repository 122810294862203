import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain } from '../containers'
import { FcCalculator, FcBullish, FcMoneyTransfer, FcComboChart, FcPositiveDynamic,
  FcBearish, FcHighPriority, FcCalendar, FcHome, FcCurrencyExchange, FcFlashOn, FcAutomotive, FcIcons8Cup, FcPieChart, FcMindMap } from "react-icons/fc";
import { MdArrowForward } from "react-icons/md";
import Blockfi from "../assets/svgs/blockfi.svg";
import Fire from "../assets/svgs/fire.svg";
import FatFire from "../assets/svgs/fat-fire-FF.svg";
import CoastFire from "../assets/svgs/coast-fire-C.svg";
import SavingsRate from "../assets/svgs/savings-rate.svg";
import BaristaFire from "../assets/svgs/barista-fire.svg";


const SectionContainer = styled(ContainerFullWidth)`
  padding: 0px 0 0px 0;
`

const FeaturedItemsGrid = styled(ContainerMain)`
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 24px; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  /* grid-template-columns: repeat(auto-fill, 200px); */
  /* justify-items: stretch; */
  /* align-items: stretch; */
  /* max-width: 1100px; */

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  h3 {
    font-size: 1rem;
    margin: 0px 0;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

`

const ToolItemPanel =styled.div`
  box-sizing: border-box;
  /* display: grid; */
  /* align-content: flex-start; */
  /* grid-template-columns: 1fr; */
  background-color: ${props => props.theme.theme.bg.primary};
  color: ${props => props.theme.theme.text.primary};
  border: 3px solid ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  display: grid;
  grid-template-rows: 75px 1fr;
  height: 175px;
  width: 175px;
  margin: 8px 8px;

  /* grid-gap: 24px; */
  border-radius: 8px;
  padding: 12px;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
  }

  h3 {
    margin: 12px 0 0 0;
    font-size: 1rem;
    font-weight: 400;
  }

  &:hover {
      /* border: 1px solid ${ props => props.theme.theme.border.tertiary}; */
    border: 3px solid ${props => props.theme.theme.colors.green};
    transition: border-color 0.2s ease-in-out;
    /* box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1); */

      h3 {
        /* color: ${props => props.theme.theme.colors.green}; */
        /* text-decoration: underline; */
      }
    }

  @media (max-width: 600px) {
    width: 300px;
    height: 150px;
  }
`

const HeroTextWrapper = styled.div`
  padding: 48px 0 0 0;
  justify-self: right;
  display: grid;
  /* grid-template-columns: auto 1fr; */
  justify-items: right;
  align-items: start;
  align-content: start;

  @media (max-width: 600px) {
    justify-items: center;
  }
`

const HeroSubtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 6px 0;
  text-align: right;
  max-width: 600px;

  span {
    /* background-color: ${props => props.theme.theme.colors.whiteTransparent}; */
    padding: 4px 8px;
  }

  @media (max-width: 600px) {
    text-align: center;
  }
`

const SvgWrapper = styled.div`

  svg {
    width: 250px;
    height: 250px;
  }
`

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.theme.colors.blue};
  border: none;
  color: ${(props) => props.theme.theme.colors.white};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px 0 0 12px;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }
`

const ViewAllLink = styled.h4`
  margin: 0;
`

const featuredTools = [
  {
    name: 'Coast FIRE Calculator',
    url: 'coast-fire-calc',
    icon: <CoastFire />,
    desc: 'How much do you need to save to Coast to FIRE?'
  },
  {
    name: 'FIRE Calculator',
    url: 'fire-calculator',
    icon: <Fire />,
    desc: 'Plan your journey to Financial Independence / Retire Early'
  },
  {
    name: 'Fat FIRE Calculator',
    url: 'fat-fire-calculator',
    icon: <FatFire />,
    desc: 'Plan your journey to FIRE with a fat nest egg'
  },
  {
    name: 'Barista FIRE Calculator',
    url: 'barista-fire-calc',
    icon: <BaristaFire />,
    desc: 'Plan your journey to FIRE with a fat nest egg'
  },
  {
    name: 'Hybrid vs Gas Savings Calculator',
    url: 'hybrid-vs-gas-savings-calculator',
    icon: <FcAutomotive />,
    desc: 'Compare annual fuel costs between a gas vehicle and a hybrid and calculate the break-even point'
  },
  {
    name: 'Rental Property Calculator',
    url: 'rental-property-calculator',
    icon: <FcHome />,
    desc: 'Analyze key financial metrics for your next real estate deal'
  }
]

const ToolItem = ( {toolItem} ) => {
  return(
    <Link to={`/tools/${toolItem.url}`}>
      <ToolItemPanel>
        {toolItem.icon}
          <h3>{toolItem.name}</h3>
      </ToolItemPanel>
    </Link>
  )
}


const FeaturedCalcs = ( props ) => {
  return (
    <SectionContainer>
      <SectionTitle>
        {/* <h3>Popular tools</h3> */}
        <Link to={`/tools/`}><ActionButton>
          View all tools
          <MdArrowForward size="20px" />
        </ActionButton></Link>
      </SectionTitle>

      <FeaturedItemsGrid>
        {featuredTools.map(tool => (<ToolItem toolItem={tool} />)) }
      </FeaturedItemsGrid>
    </SectionContainer>
  )
}

export default FeaturedCalcs
